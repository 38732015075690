import "lazysizes";
import "./swup.js";
import "swiper/css";
import { flare } from "@flareapp/js";

import Alpine from "alpinejs";
import focus from "@alpinejs/focus";
import collapse from "@alpinejs/collapse";
import intersect from "@alpinejs/intersect";

import header from "./components/header.js";
import highlights from "./components/highlights.js";
import videoPlayer from "./components/videoPlayer.js";
import gallery from "./components/gallery.js";
import zoomImage from "./components/zoomImage.js";
import teaserSlider from "./components/teaserSlider.js";
import scrollToTop from "./components/scrollToTop.js";
import counter from "./components/counter.js";

// Only enable Flare in production, we don't want to waste your quota while you're developing:
if (process.env.NODE_ENV === "production") {
    flare.light();
}

window.Alpine = Alpine;
Alpine.plugin(collapse);
Alpine.plugin(focus);
Alpine.plugin(intersect);

document.addEventListener("alpine:init", () => {
    Alpine.data("header", header);
    Alpine.data("highlights", highlights);
    Alpine.data("videoPlayer", videoPlayer);
    Alpine.data("gallery", gallery);
    Alpine.data("zoomImage", zoomImage);
    Alpine.data("teaserSlider", teaserSlider);
    Alpine.data("scrollToTop", scrollToTop);
    Alpine.data("counter", counter);

    Alpine.store("muted", false);
});

Alpine.start();
