export default () => ({
    visible: false,

    init() {
        this.onScroll();
    },

    scrollUp() {
        window.scrollTo({ top: 0, behavior: "smooth" });
        this.visible = false;
    },

    onScroll() {
        this.visible = window.scrollY > 10;
    },
});
