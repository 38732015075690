export default () => ({
    open: false,
    opened: false,
    isMobile: false,

    init() {
        this.onResize();

        this.$watch("isMobile", () => {
            this.open = false;
        });

        this.$watch("open", () => {
            if (this.open && this.isMobile) {
                setTimeout(async () => {
                    this.opened = this.open;
                }, 300);
                setTimeout(() => {
                    document.activeElement.blur();
                }, 300);
            } else {
                this.opened = this.open;
            }
        });
    },

    toggleMenu() {
        this.open = !this.open;
    },

    onEnter() {
        if (!this.isMobile) {
            this.open = true;
        }
    },

    onLeave() {
        if (!this.isMobile) {
            this.open = false;
        }
    },

    onResize() {
        this.isMobile = window.innerWidth < 1536;
    },
});
