export default () => ({
    flipped: false,

    flip(index) {
        this.flipped = this.flipped === index ? false : index;
    },

    isFlipped(index) {
        return this.flipped === index;
    },
});
