import Swup from "swup";
import SwupProgressPlugin from "@swup/progress-plugin";
import SwupPreloadPlugin from "@swup/preload-plugin";
import SwupMorphPlugin from "swup-morph-plugin";

new Swup({
    containers: ["#main", "#nextEntry", "#header"],
    plugins: [
        // new SwupMorphPlugin({
        //     containers: ['#localeSwitcher', '#reportNav', '#organisationNav', '#megaMenuButton'],
        // }),
        new SwupPreloadPlugin(),
        new SwupProgressPlugin({
            delay: 500,
        }),
    ],
});
